(function () {
	'use strict';
	angular
		.module('calendar')
		.controller('NewEventCtrl', [
			'$scope',
			'$translate',
			'seedcodeCalendar',
			'calendarIO',
			'manageEventSources',
			NewEventCtrl,
		])
		.controller('CalendarCtrl', [
			'$scope',
			'$timeout',
			'utilities',
			'dataStore',
			'seedcodeCalendar',
			'manageEventSources',
			'environment',
			CalendarCtrl,
		]);

	function NewEventCtrl(
		$scope,
		$translate,
		seedcodeCalendar,
		calendarIO,
		manageEventSources
	) {
		//Load config from our model and watch for future updates
		$scope.calendar = {};

		$scope.calendar.config = seedcodeCalendar.get('config');

		//Don't event show the popover if we are already on a day view and we are in read only mode
		if (
			$scope.calendar.config.readOnly &&
			($scope.newEvent.view == 'agendaDay' ||
				$scope.newEvent.view == 'basicDay')
		) {
			//Wrap in scope apply so we close the popover immediately and don't wait for a new digest cycle
			$scope.$apply(function () {
				$scope.popover.config.show = false;
			});
			return;
		}

		$scope.eventSourceExists = eventSourceExists();

		$scope.eventSourceEnabled = eventSourceEnabled();

		$scope.eventScheduleEnabled = eventScheduleEnabled();

		$scope.timeScheduleEnabled = timeScheduleEnabled();

		$scope.newEventDisabled = newEventDisabled();

		$scope.edit = {
			titleEdit: titleText(),
		};

		$scope.viewDay = function (date) {
			// Add property to popover config so we know selection was viewDay
			$scope.popover.config.selection = 'viewDay';
			$scope.popover.config.show = false;
			calendarIO.viewDay(date);
		};

		$scope.addEvent = function (newEvent) {
			var config = seedcodeCalendar.get('config');
			var eventTitle = newEvent.titleEdit || config.newEventTitleText;
			var breakoutData = newEvent.selectedBreakoutData;
			if (newEventDisabled()) {
				return;
			}

			// Add property to popover config so we know selection was addEvent
			$scope.popover.config.selection = 'addEvent';
			$scope.popover.config.show = false;

			calendarIO.newEvent(
				newEvent.selectedDate,
				newEvent.selectedAllDay,
				eventTitle,
				breakoutData
			);
		};
		function titleText() {
			var config = seedcodeCalendar.get('config');
			var eventTitle = config.newEventTitleText;
			return eventTitle;
		}
		function eventSourceExists() {
			var eventSources = seedcodeCalendar.get('sources');
			return eventSources.length;
		}

		function eventSourceEnabled() {
			var eventSources = manageEventSources.getSources();
			if (!seedcodeCalendar.get('sources').length) {
				return true;
			}
			for (var i = 0; i < eventSources.length; i++) {
				if (eventSources[i].enabled && !eventSources[i].readOnly) {
					return true;
				}
			}
			return false;
		}

		function eventScheduleEnabled() {
			var schedules = seedcodeCalendar.get('schedules');
			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].status.selected && schedules[i].editable) {
					return true;
				}
			}
			return false;
		}

		function timeScheduleEnabled() {
			var schedules = seedcodeCalendar.get('schedules');
			var hasAllDay;
			var schedProps;
			var ok;
			for (var i = 0; i < schedules.length; i++) {
				schedProps = Object.getOwnPropertyNames(schedules[i]);
				hasAllDay =
					schedProps.indexOf('allowAllDay') !== -1 ? true : false;
				ok = !hasAllDay ? true : false;
				ok = hasAllDay && schedules[i].allowAllDay === true ? true : ok;
				if (schedules[i].status.selected && ok) {
					return true;
				}
			}
			return false;
		}

		function newEventDisabled() {
			return (
				$scope.calendar.config.readOnly ||
				!$scope.calendar.config.hasEditableSource ||
				!$scope.eventSourceExists ||
				!$scope.eventSourceEnabled ||
				!$scope.eventScheduleEnabled ||
				(!$scope.timeScheduleEnabled && !$scope.newEvent.selectedAllDay)
			);
		}
	}

	function CalendarCtrl(
		$scope,
		$timeout,
		utilities,
		dataStore,
		seedcodeCalendar,
		manageEventSources,
		environment
	) {
		//Initialize our sources so they don't stack if we call multiple times
		manageEventSources.init();

		$scope.eventSources = manageEventSources.getSources();

		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		$scope.sidebar = {};
		$scope.sidebar = seedcodeCalendar.get('sidebar');

		$scope.isMobileDevice = environment.isMobileDevice;

		$scope.$on('eventSources', function (e, sources) {
			$scope.eventSources = manageEventSources.getSources();
		});

		$scope.$on('animate', function (e, animate) {
			$scope.calendar.animate = animate;
		});

		//We don't wan't to animate until we have already loaded all of our views
		$timeout(function () {
			$scope.calendar.animate = true;
		}, 0);

		if (!seedcodeCalendar.get('sources').length) {
			if ($scope.calendar.config.admin) {
				utilities.showMessage(
					'You will need to add at least one Calendar Source in Administrator Settings before you can create or view events.',
					3000,
					10000
				);
			} else {
				utilities.showMessage(
					'The account admin will need to add at least one Calendar Source in Administrator Settings before you can create or view events.',
					3000,
					10000
				);
			}
		}

		$scope.toggleSidebar = function () {
			//Show sidebar
			if ($scope.sidebar.show) {
				$scope.sidebar.show = false;
			}
			//Hide sidebar
			else {
				$scope.sidebar.show = true;
			}
			dataStore.saveState('showSidebar', $scope.sidebar.show);
		};

		$scope.signUp = function () {
			var url = utilities.getBaseURL() + 'sign-up';
			utilities.help(url, url, true);
		};

		$scope.runFilemakerScript = function (script) {
			utilities.scriptURL('script=' + script);
		};
	}
})();
